.footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
    @include breakpoint(S){
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .social-links {

        @include breakpoint(S) {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
        .button {
            margin: 0 0 1rem 0;

            @include breakpoint(S) {
                margin: 0 0.5rem;
            }
        }
    }
}

.footer-logo {

    @include breakpoint(S) {
        width: 100%;
        padding-right: 0;
    }
    svg {


        @include breakpoint(S) {
            width: 8rem;
            height: auto;
        }
        @include breakpoint(XS) {
            width: 6rem;
        }
    }
}




.footer-content-top {
    display: grid;
    // Basically this is saying "if there's enough space, fill in columns at comfortable width with a min-width of 16rem
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    gap: 2rem;
}

.footer-content-bottom {
    @include breakpoint(XS) {
        padding-top: 1.5rem;
        text-align: center;
    }
    span {
        margin-right: 2rem;

        @include breakpoint(S) {
            margin: 0 0 1.5rem 0;
            width: 100%;

            &:last-child {
                margin-bottom: 3rem;
            }
        }
    }
    a {
        @include color(color, 'body');

        &:hover {
            @include color(color, 'accent-primary');
        }
    }
}
.footer-main-wrap{
    position: relative;
    padding-top:18.4375rem;
    .footer-background-image{
        width: 100%;
        height:40rem;
        position: absolute;
        top: -2.8rem;
        z-index: 0;
        @include color(background-color, 'background-alt');
        img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .footer{
        display: flex;
        flex-direction: row;
        @include color(background-color, 'background');
        margin-left: 1rem;
        margin-right: 1rem;
        z-index: 2;
        position: relative;
        @include breakpoint(S){
            flex-direction: column;
        }
        .footer-line{
            width: 0.0625rem;
            @include color(background-color, 'accent-primary');
            @include breakpoint(S){
                width: 100%;
                height: 1px;
                margin-top: 2rem;
                margin-bottom: 2rem;
            }

        }
        .footer-section-one{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-right: 4.25rem;
            @include breakpoint(S){
                padding-right: unset;
            }
            svg.up-core-logo{
                path{
                    fill:$color-accent-primary;
                }
            }
            .footer-logo{
                width: 11.34rem;
                height:4.5rem;
                svg{
                    width: 100%;
                    height: 100%;
                }
            }
            .social-links{
                display: flex;
                flex-direction: row;
                gap: 1rem;
                .button.icon{
                    border: 2px solid;
                    @include color(border-color, 'body-25');
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width:2.25rem;
                    height:2.25rem;
                    svg{
                        @include color(color, 'accent-primary');
                    }
                }
            }

        }
        .footer-section-two{
            display: flex;
            flex-direction: column;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            padding-top: 0.5625rem;
            @include breakpoint(S){
                padding-left: unset;
                padding-right: unset;
            }
            h5{
                margin-bottom: 1.34375rem;
            }
            span, span a{
                @include size-S;
                @include color(color, 'body');
            }
        }
        .footer-section-three{
            padding-left: 2.5rem;
            padding-top: 0.5625rem;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex: auto;
            @include breakpoint(S){
                flex-direction: column;
                padding-left: unset;
            }
            .news-letter-text{
                max-width: 17.125rem;
                @include breakpoint(S){
                    width: 100%;
                    max-width: unset;
                }
                h5{
                    margin-bottom: 1.25rem;
                }
                p{
                    @include size-XS;
                    @include color(color, 'body-50');
                }
                .sign-up-form{
                    form{
                        input{
                            border: 2px solid;
                            border-radius: 8px;
                            @include color(background-color, 'background');
                            @include color(border-color, 'body-25');
                            border-top-right-radius: unset;
                            border-bottom-right-radius: unset;
                            border-right: unset;
                            padding-top: 0.875rem;
                            height:3rem;
                            padding-bottom: 0.875rem;
                            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                                @include color(color, 'body-25');
                                opacity: 1; /* Firefox */
                              }
                              
                            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                                @include color(color, 'body-25');
                              }
                              
                            &::-ms-input-placeholder { /* Microsoft Edge */
                                @include color(color, 'body-25');
                              }
                        }
                        button{
                            @include color(background-color, 'accent-primary');
                            border-radius: 8px;
                            margin-left: -8px;
                            z-index: 2;
                            width: 3rem;
                            height:3rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            
                            svg{
                                position: absolute;
                            }
                        }
                    }
                }
            }
            .main-footer-logos{
                display: flex;
                flex-direction: column;
                gap:2rem;
                @include breakpoint(S){
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-top: 2.25rem;
                }
                .mf-logo{
                    width:4.5rem;
                    height:4.5rem;
                    position: relative;
                    &:last-child{
                        height:3rem;
                    }
                    a {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }
                    img{
                        position: absolute;
                        width: 100%;
                        height:100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
.footer-reveal{
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 1;
    visibility: visible;
    z-index: -1;
    padding: 3rem 0;
    @include color(background-color, 'background-alt');
    .first-element-footer{
        padding: 1rem 0;
        img{
            width: 15.625rem;
            
        }
        
    }
    p{
        font-family: NotoSans;
        text-transform: uppercase;
        @include size-XS;
        @include breakpoint(S){
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
    .footer-reveal-logos{
        padding: 1rem 0;
        gap: 32px;
        @include breakpoint(S){
            width: 100%;
            gap:2rem;
            padding-left: 2rem;
        }
        a{
            width: 200px;
            height: 100px;
            margin: auto 1rem;
            position: relative;
            @include breakpoint(S){
                width:6.5rem;
                height:4.214285714285714rem;
                max-width: unset;
                margin:unset;
                flex: none;
            

            }
            img{
                object-fit: contain;
                width: 100%;
                height:100%;
                @include breakpoint(S){
                    
                }
            }
        }
    }
}